<template>
	<div class="app-container">
		<el-card class="box-card" :shadow="'hover'">
			<router-link :to="{path: '/publicare/feeduri'}">
				<el-button type="text" size="medium" icon="el-icon-arrow-left">Inapoi la feeduri</el-button>
			</router-link>
		</el-card>
		<div class="separator"></div>
		<dd-card class="box-card" :title="'Filtre'" :collapsed.sync="isCollapsed" @toggledCard="toggledCard">
			<el-row :gutter="10">
				<el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
					<div class="sub-title">Data start</div>
					<el-date-picker class="fullWidth" v-model="filters.startDate.value" value-format="yyyy-MM-dd"
						type="date" @change="applyFilters">
					</el-date-picker>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
					<div class="sub-title">Status</div>
					<el-select class="fullWidth" v-model="filters.status.value" placeholder="Toate"
						@change="applyFilters">
						<el-option label="Toate" value=""></el-option>
						<el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
					<div class="sub-title">Actiuni</div>
					<el-input v-model="filters.request_id.value" class="input-with-select fullWidth">
						<el-button slot="append" icon="el-icon-search" @click="applyFilters"></el-button>
					</el-input>
				</el-col>
			</el-row>
		</dd-card>
		<div class="separator"></div>
		<template v-if="Object.keys(historyData).length > 0">
			<h-list :data="historyData"></h-list>
			<div class="separator"></div>
		</template>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>Istoric feed</span>
			</div>
			<div class="body">
				<el-pagination class="center" layout="prev, pager, next, total" :current-page="currentPage"
					:total="count" :page-size="limit" @current-change="changePage">
				</el-pagination>
				<el-table :data="data" style="width: 100%">
					<el-table-column header-align="center" align="center" label="Tip rulare">
						<template slot-scope="scope">
							<el-tag v-if="parseInt(scope.row.run_type) === 0" type="primary">Actualizare pret si stoc
							</el-tag>
							<el-tag v-if="parseInt(scope.row.run_type) === 1" type="success">Import produse</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="Data start" prop="date_start">
					</el-table-column>
					<el-table-column label="Data final" prop="date_end">
					</el-table-column>
					<el-table-column label="ID cerere" prop="request_id">
						<template slot-scope="scope">
							{{scope.row.request_id == null ? 'Rulat automat' : scope.row.request_id}}
						</template>
					</el-table-column>
					<el-table-column label="Rezultat" header-align="center" align="center">
						<template slot-scope="scope">
							<el-tag v-if="scope.row.result === 0 || scope.row.result == null" type="danger">Eroare la
								rulare</el-tag>
							<el-tag v-if="scope.row.result === 1" type="success">Rulat cu succes</el-tag>
							<el-tag v-if="scope.row.result === -1" type="primary">Setari incomplete</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="Adaugate/Actualizate" prop="updated" header-align="center" align="center">
					</el-table-column>
					<el-table-column label="Refuzate" prop="refused" header-align="center" align="center">
					</el-table-column>
					<el-table-column label="ID cerere" prop="request_id">
						<template slot-scope="scope">
							<el-button type="primary" icon="el-icon-tickets"
								@click="viewFeedHistoryDetails(scope.row.id)">Detalii</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination class="center" layout="prev, pager, next, total" :current-page="currentPage"
					:total="count" :page-size="limit" @current-change="changePage">
				</el-pagination>
			</div>
		</el-card>
	</div>
</template>

<script>
	const Box = () =>
		import('vue-info-box-element');
	const DDCard = () => import('@/components/DDCard');
	const HistoryList = () => import('./components/feed-history-list');

	import {
		createFeedsStore
	} from '@/store/modules/feeds/index.js';

	export default {
		components: {
			Box,
			'dd-card': DDCard,
			'h-list': HistoryList
		},
		props: ['filterQuery', 'id'],
		data() {
			return {
				isCollapsed: true,
				limit: 5,
				currentPage: 1,
				count: 0,
				data: [],
				filters: {
					startDate: {
						type: 'select',
						value: ''
					},
					status: {
						type: 'select',
						value: ''
					},
					start: {
						type: 'page',
						value: 0
					},
					request_id: {
						type: 'input',
						value: ''
					}
				},
				statusList: [{
					value: '0',
					label: 'Eroare la rulare'
				}, {
					value: '1',
					label: 'Rulat cu succes'
				}, {
					value: '-1',
					label: 'Setari incomplete'
				}],
				historyData: {}
			};
		},
		methods: {
			toggledCard() {
				this.isCollapsed = !this.isCollapsed;
			},
			getFeedHistory() {
				this.$store.dispatch('feeds/GetFeedHistory', {
					id: this.id,
					start: this.filters.start.value,
					startDate: this.filters.startDate.value,
					status: this.filters.status.value,
					request_id: this.filters.request_id.value
				}).then((res) => {
					if (typeof res.message === 'undefined' || typeof res.message.count === 'undefined') {
						return false;
					}
					this.count = res.message.count;
					this.data = res.message.data;
					this.currentPage = Math.ceil(this.filters.start.value / this.limit) + 1;
				}).catch((err) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'getFeedHistory'
						}
					});
				});
			},
			changePage(page) {
				this.filters.start.value = this.limit * (page - 1);
				this.currentPage = page;
				this.getFeedHistory();
			},
			hasOutput(output) {
				if (typeof output === 'undefined' || output == null) return false;
				try {
					const o = JSON.parse(output);
					if (o == '') return false;
				} catch (err) {
					return false;
				}
				return true;
			},
			applyFilters() {
				if (typeof this.updateQuery === 'function') this.updateQuery();
				this.getFeedHistory();
			},
			viewFeedHistoryDetails(logId) {
				this.historyData = {};
				this.$store.dispatch('feeds/ViewFeedHistoryDetails', {
					id: logId,
					feed_id: this.id
				}).then((res) => {

					if (typeof res.message !== 'undefined' && typeof res.message.feedData !== 'undefined' && typeof res.message.runData !== 'undefined') {
						this.historyData = res.message;
					}

				}).catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'viewFeedHistoryDetails',
							params: {
								id: logId,
								feed_id: this.id
							}
						}
					});
				});
			}
		},
		computed: {},
		mounted() {
			this.getFeedHistory();
		},
		beforeCreate() {
			this.$store.registerModule('feeds', createFeedsStore());
		},
		beforeDestroy() {
			this.$store.unregisterModule('feeds');
		}
	};
</script>